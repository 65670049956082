import React, { Component } from "react";

class ChatFeatures2 extends Component {
  render() {
    let {
      rowClass,
      col1,
      col2,
      pClass
    } = this.props;
    return (
      <section className="chat_features_area">
        <div className="container">
          <div className={`row align-items-center ${rowClass}`}>
            <div className={col1}>
                <img
                  className="dot_bg"
                  src={require("../../img/home-chat/2-how-to.png")}
                  alt="" />
            </div>
            <div className={col2}>
              <div className={`chat_features_content ${pClass}`}>
                <h2>
                  2) <span>Übersetzen</span>
                </h2>
                <p>
                    Nun haben Sie die Möglichkeit beliebige Sprachversionen von Ihrem Video zu erstellen. 
                    Einfach die gewünschte Zielsprache auswählen, die Übersetzung und Sprachausgabe 
                    erfolgt automatisch im Hintergrund.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ChatFeatures2;