import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import CreditsSection from '../components/CreditsSection';
import FooterErp from '../components/Footer/FooterErp';

const Credits = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Impressum" Pdescription=""/>
            <CreditsSection/>
            <FooterErp fClass="event_footer_area" />
        </div>
    )
}
export default Credits;