import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import ChatBanner from "../components/Banner/ChatBanner";
import CoreFeatures from "../components/Features/CoreFeatures";
import ChatFeatures1 from "../components/Features/ChatFeatures1";
import ChatFeatures2 from "../components/Features/ChatFeatures2";
import ChatFeatures3 from "../components/Features/ChatFeatures3";
import ChatFeatures4 from "../components/Features/ChatFeatures4";
import CustomersSection from "../components/CustomersSection";
import ChatClients from "../components/ChatClients";
import FooterErp from "../components/Footer/FooterErp";

const homechat = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar nClass="m-auto" hbtnClass="new_btn" />
      <ChatBanner />
    
      <ChatFeatures1 rowClass='flex-row-reverse' pClass="pr_70" col1="col-lg-6 text-center" col2="col-lg-6" />
      <ChatFeatures2 pClass="pl_70" col1="col-lg-6 text-center" col2="col-lg-6" />
      <ChatFeatures3 rowClass='flex-row-reverse' pClass="pr_70" col1="col-lg-6 text-center" col2="col-lg-6" />
      <ChatFeatures4 pClass="pl_70" col1="col-lg-6 text-center" col2="col-lg-6" />
     
      <CoreFeatures />
      <CustomersSection />
      <ChatClients/>
      <FooterErp fClass="event_footer_area" />
    </div>
  );
};
export default homechat;

