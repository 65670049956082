import React, { Component } from "react";

class ChatFeatures1 extends Component {
  render() {
    let {
      rowClass,
      col1,
      col2,
      pClass
    } = this.props;
    return (
      <section id="section_service" className="chat_features_area">
        <div className="container">
          <div className="chat_title text-center">
            <h2 className="wow fadeInUp">In wenigen Schritten  <span>alles erklären</span>!</h2>
          </div>
          <div className={`row align-items-center ${rowClass}`}>
            <div className={col1}>
                <img
                  className="dot_bg"
                  src={require("../../img/home-chat/1-how-to.png")}
                  alt=""
                />
            </div>
            <div className={col2}>
              <div className={`chat_features_content ${pClass}`}>
                <h2>
                  1) <span>Aufnehmen</span>
                </h2>
                <p>
                    Filmen Sie die Tätigkeit einfach mit Ihrem Smartphone und sprechen Sie dazu. 
                    Wie man dabei am Besten vorgeht, erklären wir in einem Video. Wenn es im Arbeitsbereich 
                    zu laut ist, kann das Video auch noch im Anschluß besprochen werden.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ChatFeatures1;
