import React from 'react';

const CreditsSection =()=>{
    return(
        <section className="faq_area bg_color sec_pad">
            <div className="container">
                <div className="row"> 
                    <div className="col-lg-12">
                        <div className="tab-content">

                            <div className="tab-pane show active">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Verwendete Bilder und Videomaterial</h3>
                                <p>
                                    <a href="https://www.freepik.com/free-photo/cheerful-asian-male-janitor-walking-into-hotel-room-carrying-supplies-bucket_5577541.htm">Image by pressfoto</a> on Freepik
                                </p>
                                <p>
                                    Image by <a href="https://www.freepik.com/free-photo/adult-woman-proud-her-pastries_6103132.htm#query=gimbal%20smartphone&position=9&from_view=search&track=ais">Freepik</a>
                                </p>
                                <p>
                                    <a href="https://www.freepik.com/free-photo/professional-industrial-cleaner-protective-uniform-cleaning-floor-food-processing-plant_11450718.htm#query=worker%20cleaning&position=6&from_view=search&track=ais">Image by aleksandarlittlewolf</a> on Freepik
                                </p>
                                <p>
                                <a href="https://www.freepik.com/free-photo/construction-worker-with-contemporary-mobile-phone_11230069.htm#query=worker%20mobile%20app&position=0&from_view=search&track=ais">Image by gpointstudio</a> on Freepik
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CreditsSection;