import React, { useState } from "react";
import VideoSlider from "../VideoSlider";
import validator from 'validator';
import axios from 'axios';

const ChatBanner = () => {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");

  const submitButtom = () => {
    if (validator.isEmail(email.trim())) {

      axios.post('https://digitale-kampagne.at/external/explaincampaign', { email, message})
      .then(function () {
        setEmailSent(true);
      })
      .catch(function () {
        setError("Es gibt ein Problem, die Nachricht konnte nicht gesendet werden! Bitte probieren Sie es später nochmal, oder kontaktieren Sie uns unter support@joobster.at");
      });

    } else {
      setError("Bitte überprüfen Sie die eingegebene Email-Adresse!");
    }
  }; 
 
  return (
    <section id="section_home" className="chat_banner_area">
      <img
        className="p_absoulte cloud"
        data-parallax='{"x": 0, "y": 150}'
        src={require('../../img/home-chat/cloud.png')}
        alt=""
      />
      <img
        className="p_absoulte left wow fadeInLeft"
        data-wow-delay="0.2s"
        src={require('../../img/home-chat/left_leaf.png')} 
        alt=""
      />
      <img
        className="p_absoulte right wow fadeInRight"
        data-wow-delay="0.3s"
        src={require('../../img/home-chat/right_leaf.png')}
        alt=""
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="chat_banner_content">
              <div className="c_tag wow fadeInUp">
                <img src={require("../../img/home-chat/live_chat.png")} alt="Einfach alles erklären, per Video" />
                Einfach alles erklären, per Video
              </div>
              <h2 className="wow fadeInUp" data-wow-delay="0.2s">
                <small>Sparen Sie bis zu 90% Ihrer Zeit!</small><br/> 
                <strong>Erklären Sie alle Aufgaben per Video</strong>, 
                in mehr als <strong>20 Sprachen</strong>.
              </h2>

              <p> 
              Nehmen Sie alle <strong>Arbeitsschritte mit dem Smartphone auf</strong> und sprechen 
              Sie dazu. Sie erhalten Ihr Video in <strong>mehr als 20 Sprachen</strong> übersetzt. 
              </p>
              <p>
              Teilen Sie das Video direkt in Ihren <strong>WhatsApp-Gruppen</strong>, 
              oder hinterlegen Sie Testfragen um zu prüfen, ob auch wirklich alles verstanden wurde.
              </p>

              <div className="text-left chat_banner_arguments">
                  <img src={require("../../img/whatsapp-small.png")} alt="" />
                  <strong className="c_tag">Videos direkt in WhatsApp teilen</strong>
              </div>

              <p>
              einfach-alles-erklären unterstützt Sie bei der Erstellung und Verwaltung Ihrer 
              Inhalte <strong>schnell</strong> und <strong>kostengünstig</strong>! 
              </p>

              <div className="text-left chat_banner_arguments">
              <img src={require("../../img/home-chat/chat_icon.png")} alt="" />
                  <strong className="c_tag">
                    Hinterlassen Sie uns einfach eine Nachricht
                  </strong>
              </div>

              { emailSent ? (
                <div className="text-center wow fadeInUp" data-wow-delay="0.4s">
                  <div className="form-group text_box">
                    <p>
                      <br/>
                      <strong>Vielen Dank für Ihr Interesse! Wir haben Ihre Nachricht erhalten, und 
                      werden Sie sobald als möglich kontaktieren!</strong>
                    </p>
                    <p>&hearts; Ihr joobster Team</p>
                  </div>
                </div>
              ) : (
                <div className="text-center pr_100 wow fadeInUp" data-wow-delay="0.4s">

                  { error.length > 0 && (
                    <div className="form-group text_box">
                      <p className="chat_banner_email_validator">{ error }</p>
                    </div>
                  )}

                  <div className="form-group text_box">
                    <input type="text" name="email" id="email" placeholder="Ihre Email-Adresse" onChange={event => setEmail(event.target.value)} />
                  </div>

                  <div className="form-group text_box">
                      <textarea name="message" id="message" cols="30" rows="2" maxLength="800" placeholder="Ihre Nachricht (800 Chars max.) . . ." onChange={event => setMessage(event.target.value)}></textarea>
                  </div>

                  <a href="/#" className="chat_btn btn_hover" onClick={submitButtom}>
                    Ja, ich möchte gerne mehr erfahren
                  </a>
                  
                </div>
              )}

            </div>
          </div>
          <div className="col-lg-6">
            <div className="chat_img">
                <VideoSlider />
            </div>
           
            <div className="text-center wow fadeInUp margin_left_50_30" data-wow-delay="0.4s">

                <div className="text-left chat_banner_arguments">
                  <img src={require("../../img/home-chat/ok.png")} alt="" />
                  <strong className="c_tag">In mehr als 20 Sprachen erstellen</strong>
                </div>

                <div className="text-left chat_banner_arguments">
                  <img src={require("../../img/home-chat/ok.png")} alt="" />
                  <strong className="c_tag">Videos jederzeit spielend leicht erweitern</strong>
                </div>

                <div className="text-left chat_banner_arguments">
                  <img src={require("../../img/home-chat/ok.png")} alt="" />
                  <strong className="c_tag">Testfragen hinterlegen und Zertifikat erhalten</strong>
                </div>

              </div>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatBanner;
