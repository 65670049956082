import React, { Component } from "react";

class ChatFeatures4 extends Component {
  render() {
    let {
      rowClass,
      col1,
      col2,
      pClass
    } = this.props;
    return (
      <section className="chat_features_area">
        <div className="container">
          <div className={`row align-items-center ${rowClass}`}>
            <div className={col1}>
                <img
                  className="dot_bg"
                  src={require("../../img/home-chat/4-how-to.png")}
                  alt="" />
            </div>
            <div className={col2}>
              <div className={`chat_features_content ${pClass}`}>
                <h2>
                  4) <span>Verändern</span>
                </h2>
                <p>
                    Und, wenn sich der Ablauf mal ändert? Kein Problem, einfach die 
                    Aufnahme durch eine neue ersetzen, und Videos neu erstellen lassen. 
                </p>
                <p>
                    Die Videos können zu jeder Zeit mit neuen Aufnahmen ergänzt werden. 
                    Einfacher geht's nicht! 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ChatFeatures4;
