import React, { Component } from "react";

class ChatFeatures3 extends Component {
  render() {
    let {
      rowClass,
      col1,
      col2,
      pClass
    } = this.props;
    return (
      <section className="chat_features_area">
        <div className="container">
          <div className={`row align-items-center ${rowClass}`}>
            <div className={col1}>
                <img
                  className="dot_bg"
                  src={require("../../img/home-chat/3-how-to.png")}
                  alt="" />
            </div>
            <div className={col2}>
              <div className={`chat_features_content ${pClass}`}>
                <h2>
                  3) <span>Teilen</span>
                </h2>
                <p>
                    Teilen Sie das Video sofort in Ihren WhatsApp-Gruppen. 
                </p>
                <p>
                    Wenn Sie sicher gehen wollen, dass die Inhalte verstanden wurden, können
                    Sie zu jedem Video Fragen hinterlegen und diese in einem kurzen Check 
                    überprüfen. Das erstellte Zertifikat wird sicher in der Cloud abgelegt. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ChatFeatures3;
